import { footPrint } from "@/api/member/member"

export default {
    name: "footprint",
    components: {

    },
    data: () => {
        return {
            footprintList: [],
            // footprint: [],
            footprintEmpty: false,
            footprintSelect: true,
            checkAll: false,
            checkedCities: [],
            isIndeterminate: true,
            deletefootprint: false,
            strCurXSoyego: localStorage.getItem("strCurXSoyego"),
        }
    },
    watch: {
        '$route': 'fetchData',
        checkedCities: {
            deep: true,
            handler: function(val){
                this.checkedCities = val;
                this.fetchData();
            },
        },
        checkAll: {
            deep: true,
            handler: function(val){
                this.checkAll = val;
                this.fetchData();
            },
        }
    },
    created() {
        this.getFootPrint();
    },
    mounted() {
        if(this.footprint.length <= 0){
            this.footprintEmpty = true;
        }else{
            this.footprintEmpty = false;
        }
    },
    computed: {

    },
    methods: {
        fetchData() {
            this.getFootPrint();
            if(this.footprint.length <= 0){
                this.footprintEmpty = true;
            }else{
                this.footprintEmpty = false;
            }
        },
        getFootPrint() {
            let params = {
                page: 1,                
                page_size: 40
            }
            footPrint(params).then(res => {
                if(res.code == 0 && res.data) {
                    // console.log(res.data)
                    res.data.list.forEach((item, index) => {
                        this.$set(item, "label", index);
                    })
                    this.footprintList = res.data.list;
                    // console.log(this.footprintList)
                }
            }).catch(err => {
                // console.log(err)
                this.$message.error(err.message);
            });
        },
        backClick() {
            this.$router.go(-1);
        },
        selectClick() {
            this.footprintSelect = !this.footprintSelect;
            this.fetchData();
        },
        handleCheckAllChange(val) {
            let options = [];
            this.footprint.forEach((item, index) => {
                options[index] = item.label;
            });
            this.checkedCities = val ? options : [];
            this.isIndeterminate = false;
        },
        handleCheckedCitiesChange(value) {
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.footprint.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.footprint.length;
        },
        handleClose() {
            this.deletefootprint = false;
        },
        deleteNo(val) {
            this.deletefootprint = val;
        },
        deleteYes(val) {
            this.deletefootprint = val;
        },
        deleteClick() {
            if(this.checkedCities.length > 0){
                this.deletefootprint = true;
            }else{
                this.$message({ message: 'Please select the item to delete', type: 'warning' });
            }
        }
    }
}
