<template>
    <div class="footprint">
        <div class="footprint-title">
            <div class="back" @click="backClick">
                <img src="@/assets/images/personal/z.png" />
                <span>Back</span>
            </div>
            <h5>Footprint</h5>
        </div>
        <div class="footprint-main" v-show="!footprintEmpty && footprintSelect">
            <div class="main-collect">
                <div class="collect-title">
                    <div></div>
                    <span @click="selectClick">Select</span>
                </div>
                <div class="collect-main">
                    <div class="item" v-for="(item, index) in footprintList" :key="index">
                        <div class="img">
                            <el-image :lazy-src="item.sku_image" :src="item.sku_image" fit="cover" style="cursor: pointer; height: 100%"></el-image>
                            <!-- <img :src="item.imgUrl" /> -->
                        </div>
                        <span>{{ item.goods_name }}</span>
                        <p>{{ strCurXSoyego }}{{ item.discount_price }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="footprint-main" v-show="!footprintEmpty && !footprintSelect">
            <div class="main-select">
                <div class="select-title">
                    <div>
                        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">Select all</el-checkbox>
                    </div>
                    <div class="text">
                        <span @click="deleteClick">Delete({{ checkedCities.length }})</span>
                        <p @click="footprintSelect = true">Done</p>
                    </div>
                </div>
                <div class="select-main">
                    <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
                        <div class="item" v-for="(item, index) in footprintList" :key="index">
                            <div class="img">
                                <el-image :lazy-src="item.sku_image" :src="item.sku_image" fit="cover" style="cursor: pointer; height: 100%"></el-image>
                                <!-- <img :src="item.imgUrl" /> -->
                            </div>
                            <span>{{ item.goods_name }}</span>
                            <p>{{ strCurXSoyego }}{{ item.discount_price }}</p>
                            <el-checkbox size="medium" :label="item.label">&nbsp;</el-checkbox>
                        </div>
                    </el-checkbox-group>
                </div>
            </div>
        </div>

        <div v-show="footprintEmpty" class="footprint-empty">
            <img src="@/assets/images/personal/footprintEmpty.png" />
            <span>Your footprint is empty</span>
            <button>SHOP NOW</button>
        </div>

        <el-dialog :visible.sync="deletefootprint" @closed="handleClose" width="23%" style="margin-top: 20vh">
            <deleteFootprint @deteleNO="deleteNo" @deteleYes="deleteYes"></deleteFootprint>
        </el-dialog>
    </div>
</template>

<style>
.footprint .el-input__inner {
    border-radius: 0;
}
.footprint .el-checkbox__inner {
    background-color: rgba(29, 29, 29, 0.3);
    border-color: #ffffff;
    border-radius: 50%;
    width: 18px;
    height: 18px;
}

.footprint .el-checkbox__input.is-checked .el-checkbox__inner,
.footprint .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    border-color: #330000;
    background-color: #330000;
    color: #330000;
    width: 18px;
    height: 18px;
}
.footprint .el-checkbox__inner::after {
    left: 6px;
    top: 3px;
}
.footprint .el-checkbox__input.is-indeterminate .el-checkbox__inner::before {
    top: 7px;
}
.footprint .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #330000;
}
.footprint .el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: #ffffff;
    width: 18px;
    height: 18px;
}

.about-content {
    background: #ffffff;
}
</style>
<style lang="scss" scoped>
@import "./css/footprint.scss";
</style>

<script>
import footprint from "./js/footprint.js"
import deleteFootprint from "./alert/deleteFootprint"

export default {
    name: "footprint",
    components: {
        deleteFootprint
    },
    mixins: [footprint]
}
</script>