<template>
    <div class="delete">
        <h5>Are you sure you want to delete these items? </h5>
        <span>Can't be recovered after deletion</span>
        <div>
            <button class="no" @click="noClick">NO</button>
            <button class="yes" @click="yesClick">YES</button>
        </div>
    </div>
</template>

<script>
export default {
    name: "deleteFootprint",
	components: {

	},
	data: () => {
		return {
            delete: false
		}
	},
	methods: {
        noClick() {
            this.$emit("deteleNO", this.delete);
        },
        yesClick() {
            this.$emit("deteleYes", this.delete);
        }
	}
}
</script>


<style lang="scss" scoped>
.delete {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;

    h5 {
        font-size: 14px;
        font-family: Montserrat,SF-UI;
        color: #330000;
    }
    span{
        font-size: 14px;
        font-family: Montserrat,SF-UI;
        color: #FF5656;
        padding-bottom: 40px;
    }
    > div {
        display: flex;
        justify-content: space-between;

        > button {
            width: calc(50% - 11px);
            outline: none;
            border: none;
            cursor: pointer;
            padding: 15px 40px;
            font-size: 16px;
            font-family: Montserrat,SF-UI;
            font-weight: bold;
            border: 1px solid #330000;
        }
        .no{
            background: #330000;
            color: #FFFFFF;
        }
        .yes{
            background: #fff;
            color: #330000;
        }
    }
}
</style>